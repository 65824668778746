module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Christopher Frewin Photography","short_name":"Christopher Frewin Photography","description":"A selection of fine art landscapes.","start_url":"photography.chrisfrew.in","lang":"en","background_color":"#000000","theme_color":"#ffffff","display":"standalone","icon":"src/assets/icon/maskable_icon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a1c26783682ab34faf215c9127f42d87"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
